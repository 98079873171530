<template>
  <b-modal
    id="pause-modal"
    hide-header
    hide-footer
    no-stacking
    centered
    size="md"
    @show="onShow"
  >
    <div class="header">
      <div class="header-content">
        <p>Finalizar depois</p>
        <Close @click="closeModal" />
      </div>
    </div>
    <hr>
    <div class="bottom">
      <div class="body">
        <div class="radio-container">
          <p>Selecione umas das opções antes de prosseguir</p>

          <div class="radio-section">

            <div class="radio">
              <input
                checked
                type="radio"
                name="attendance_status"
                id="draft-medical-report"
                value="save-draft"
                @input="getRadioValue"
              >
              <label for="pre-finished_attendance">Salvar com rascunho e continuar depois</label>
            </div>

            <div class="radio">
              <input
                type="radio"
                name="attendance_status"
                id="remove-draft"
                value="discard-draft"
                @input="getRadioValue"
              >
              <label for="stand-by_attendance">Sair sem salvar o conteúdo</label>
            </div>
            <span style="color: #8696AC;">As informações do laudo médico serão perdidas.</span>
          </div>
        </div>
      </div>

      <div class="footer">
        <button class="btn cancel-button" @click="closeModal">
          Cancelar
        </button>

        <button class="btn confirm-button" @click="confirm">
          Confirmar
        </button>
      </div>
    </div>

  </b-modal>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Close from '@/assets/icons/close.svg'

export default {
  components: {
    Close
  },
  computed: {
    ...mapState({
      attendance: state => state.attendance.attendance,
      form: state => state.attendance.form.laudoMedico
    })
  },
  data() {
    return {
      selected: null
    }
  },
  props: {
    attendanceRedirect: String
  },
  methods: {
    ...mapActions('attendance', ['closeAttendance', 'clearAttendance']),
    ...mapActions('attendance/form/laudoMedico', [
      'handleFields',
      'handleProps'
    ]),
    ...mapActions('attendance/form', ['updateMedicalRecord']),
    onShow() {
      this.selected = 'save-draft'
    },
    closeModal() {
      this.$bvModal.hide('pause-modal')
    },
    getRadioValue(val) {
      this.selected = val.target.value
    },
    async confirm() {
      this.$bvModal.hide('pause-modal')
      if (this.selected === 'save-draft') {
        await this.pauseAttendance()
      }
      if (this.selected === 'discard-draft') {
        await this.discardDraft()
      }
      this.closeAttendance()
      this.$router.push(this.attendanceRedirect)
      this.clearAttendance()
    },
    async discardDraft() {
      const value = []
      this.handleProps({ key: 'value', value })
      await  this.updateMedicalRecord(this.form)
    },
    async pauseAttendance() {
      try {
        await this.api.pauseAttendance(this.attendance.clinic.id, this.attendance.id, this.pauseReason)
        this.$toast.success('Laudo pausado com sucesso')
      } catch (err) {
        this.$toast.error(err.message)
      }
    }
  }
}
</script>
<style lang="scss">
#pause-modal {
  font-family: 'Nunito Sans';
  font-style: normal;

  .draft-notice {
    background-color: var(--neutral-100);
    border-radius: 8px;
    color:  var(--type-active);
  }

  .modal-content {
    top: 50%;
    left: 50%;
    transform: translate(-50%, 0);
    max-width: 800px;

    .modal-body {
      padding: 0;

      .header {
        .header-content {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 24px;

          p {
            font-weight: 600;
            font-size: 18px;
            line-height: 28px;
            color: #525C7A;
          }

          svg {
            height: 24px;
            width: 24px;
            fill: #7F91D2;
            cursor: pointer;
          }
        }
      }

      hr {
        margin: 0;
      }

      .bottom {
        display: flex;
        flex-direction: column;
        gap: 40px;
        padding: 24px;

        .body {
          .radio-container {
            display: flex;
            flex-direction: column;
            gap: 24px;

            p {
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 24px;
              color: var(--type-active);
            }

            .radio-section {
              display: flex;
              flex-direction: column;
              gap: 16px;

              .radio {
                display: flex;
                align-items: center;
                gap: 12px;

                label {
                  margin: 0;
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 150%;
                  color: #525C7A;
                  cursor: pointer;
                }

                input {
                  height: 24px;
                  width: 24px;
                  cursor: pointer;
                }
              }
            }
          }

          hr {
            margin: 24px 0;
          }
        }

        .footer {
          display: flex;
          gap: 16px;
          justify-content: right;

          .cancel-button {
            padding: 14px 0;
            font-weight: 600;
            font-size: 18px;
            line-height: 28px;
            color: #F63220;
          }

          .confirm-button {
            padding: 14px 16px;
            background: #305BF2;
            border-radius: 8px;
            font-weight: 600;
            font-size: 18px;
            line-height: 28px;
            color: #FFFFFF;
          }
        }
      }
    }
  }
}
</style>
